import React from "react"
import Catalog from "../../static/catalog.pdf"

function ProductCatalog({}) {
  return (
    <div className="my-32 w-full bg-secondery">
      <div className="md:flex">
        <div className="w-full md:w-2/3">
          <p className="py-16 px-24 text-center font-gothic leading-loose md:text-left">
            掲載外のプロダクトも多数ございます。
            <br />
            詳しくはカタログをご覧ください。
          </p>
        </div>
        <div className="my-auto pb-8 text-center md:pb-0 lg:w-1/3">
          <a href={`${Catalog}`} download="カタログ.pdf">
            <button className="rounded border border-primary py-4 px-8 font-mincho text-primary hover:border-none hover:bg-primaryHover hover:text-white">
              カタログのダウンロード
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProductCatalog
