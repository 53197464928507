import React from "react"
import styled from "styled-components"

function Toptitle({ children }) {
  return (
    <TopTitleWrapper className="lg:pt-24 lg:text-right lg:font-mincho lg:text-8xl lg:text-primarySupport">
      {children}
    </TopTitleWrapper>
  )
}

const TopTitleWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`

export default Toptitle
