import React from "react"

function ProductDetail({ name, size, price }) {
  return (
    <div className="font-gothic">
      <span className="block text-lg text-primary">{name}</span>
      <span className="mt-1 block">{size}</span>
    </div>
  )
}

export default ProductDetail
