import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ProductDetail from "../../components/products/productDetail"
import ProductCatalog from "../../components/products/productCatalog"
import Toptitle from "../../components/toptitle"

const Molding = () => (
  <Layout>
    <SEO title="モールディング | 製品紹介" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Molding</Toptitle>
        <div className="w-full pt-32 lg:mt-16 lg:pt-0">
          <div className="w-full md:flex">
            <StaticImage
              src="../../images/products/molding.jpg"
              alt="モールディングの事例"
              className="w-full md:w-1/2"
            />
            <div className="w-full bg-secondery py-8 px-12 md:w-1/2">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                モールディング
              </h2>
              <p className="font-gothic leading-loose">
                天井廻り縁や額縁などに利用される装飾材の総称で、モルダーという特殊な設備を使い製作します。
                <br />
                オーダメイドで様々な形状に対応できる他、曲面仕様も可能です。
              </p>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-32 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:mx-0">
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-01.png" />
            <ProductDetail name="AM-01" size="160×100" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-03.png" />
            <ProductDetail name="AM-03" size="100×90" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-05.png" />
            <ProductDetail name="AM-05" size="75×45" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-15.png" />
            <ProductDetail name="AM-15" size="75×35" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-21.png" />
            <ProductDetail name="AM-21" size="60×20" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-27a.png" />
            <ProductDetail name="AM-27a" size="160×100" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-31a.png" />
            <ProductDetail name="AM-31a" size="50×15" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/molding/am-35a.png" />
            <ProductDetail name="AM-35a" size="50×30" />
          </div>
        </div>
        <ProductCatalog />
      </section>
    </div>
  </Layout>
)

export default Molding
